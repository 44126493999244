import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "sv-success",
  "seo": {
    "title": "-",
    "descr": "-"
  },
  "bgImage": "/img/bg-about.jpg",
  "personImage": "/img/daniel.png",
  "title": "Tack för att du har tagit kontakt med oss!",
  "lines": [{
    "text": "Vi kommer kontakta dig."
  }, {
    "text": "Du kommer snart få ett bekräftelsemail"
  }, {
    "text": "till den mailadress som du har angivit."
  }, {
    "text": "Vänliga hälsningar"
  }],
  "personName": "Daniel Baldwin",
  "role": "COO"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      